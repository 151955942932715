import { createSlice } from '@reduxjs/toolkit' //importing create slice

// define initialState

const initialState = {
  user: null,
}

// function to export
const userSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload
    },
    logout: (state) => {
      state.user = null
    },
  },
})

export const { login, logout } = userSlice.actions

// selectors
export const selectUser = (state) => state.userInfo.user

export default userSlice.reducer
