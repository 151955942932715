import React, { useEffect, useRef, useState } from 'react'
import Header from '../components/common/Header'
import {
  Row,
  Col,
  Badge,
  ListGroup,
  Card,
  Button,
  Form,
  Modal,
  Accordion,
  ListGroupItem,
} from 'react-bootstrap'
import {
  MapContainer,
  LayersControl,
  TileLayer,
  useMapEvents,
} from 'react-leaflet'
import './css/planTrip.css'

import EsriLeafletGeoSearch from 'react-esri-leaflet/plugins/EsriLeafletGeoSearch'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser, login, logout } from '../redux_setup/slices/userSlice'
import CustomMarker from '../components/utilities/CustomMarker'
import { fetchDiseaseArray } from '../functions'
import { empty, selectDisease } from '../redux_setup/slices/diseaseSlice'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import BottomNavigation from '../components/common/BottomNavigation'

const PlanTripScreen = () => {
  const navigate = useNavigate()
  const [trips, setTrips] = useState([])
  const [tripName, setTripName] = useState('')
  const [currentView, setCurrentView] = useState('addStops')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [currentCoordinates, setCurrentCoordinates] = useState([])
  const [thisMap, setThisMap] = useState()
  const [placeName, setPlaceName] = useState('Searched Spot')
  const [region, setRegion] = useState('accurate')
  const [fetchDiseases, setFetchDiseases] = useState({})
  const [totalFetchedDiseases, setTotalFetchedDiseases] = useState([])
  const [searchFinished, setSearchFinished] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [dis, setDis] = useState('')
  const [sections, setSections] = useState([])
  const [sectionsLoading, setSectionsLoading] = useState(false)
  const [place, setPlace] = useState([])
  const [showReport, setShowReport] = useState(false)
  const [reportLoading, setReportLoading] = useState(false)
  const [
    totalFetchedDiseasesWithInfo,
    setTotalFetchedDiseasesWithInfo,
  ] = useState([])

  const [country, setCountry] = useState('')
  let accessUrl = 'http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}'

  toast.configure()
  const disease = useSelector(selectDisease)
  const user = useSelector(selectUser)
  const dispatch = useDispatch()

  const url = process.env.REACT_APP_MAIN_URL
  const modalRef = useRef(null)
  let travelAnalysisUrlInitial =
    'https://maps.diseaseadviser.com:8443/travel_analysis.php'
  // const location = useLocation()

  useEffect(() => {
    if (user?.user_type != 2) {
      navigate('/payments')
    }
  }, [])

  useEffect(() => {
    if (disease) {
      // console.log('dddddd', disease?.diseasename)
      setDis(disease?.diseasename)
      viewDisease(disease)
    }
    return () => {
      dispatch(empty())
    }
  }, [disease])

  useEffect(() => {
    // console.log('fetchDiseases', fetchDiseases)
    if (!!fetchDiseases?.placeName) {
      console.log('fetchDiseases exists', fetchDiseases)

      // const seenIds = new Set()
      // const unique = []

      // fetchDiseases.forEach((obj) => {
      //   if (!seenIds.has(obj.disease_id)) {
      //     seenIds.add(obj.disease_id)
      //     unique.push(obj)
      //   }
      // })
      const totalFetchedDiseasesClone = [...totalFetchedDiseases]
      // totalFetchedDiseasesClone.push(fetchDiseases)
      //ensure that fetchedDiseases.placeName is not already in totalFetchedDiseases
      const placeName = fetchDiseases.placeName
      const placeNameExists = totalFetchedDiseases.some(
        (disease) => disease.placeName === placeName,
      )
      if (!placeNameExists) {
        totalFetchedDiseasesClone.push(fetchDiseases)
      }

      setTotalFetchedDiseases(totalFetchedDiseasesClone)
    }
  }, [fetchDiseases])

  // useEffect(() => {
  //   console.log('fetchDiseases', fetchDiseases)
  //   if (fetchDiseases.length > 0) {
  //     console.log('fetchDiseases', fetchDiseases)
  //   }
  //   const seenIds = new Set()
  //   const unique = []

  //   fetchDiseases.forEach((obj) => {
  //     if (!seenIds.has(obj.disease_id)) {
  //       seenIds.add(obj.disease_id)
  //       unique.push(obj)
  //     }
  //   })

  //   setTotalFetchedDiseases(unique)
  // }, [fetchDiseases])
  //useEffect t push to trips array when place changes
  useEffect(() => {
    if (place.length > 0) {
      const tripsClone = [...trips]
      tripsClone.push(place)
      setTrips(tripsClone)
    }
  }, [place])

  function getUniqueDiseases(arr) {
    const uniqueDiseases = arr.reduce((acc, curr) => {
      curr.diseasesArray.forEach((disease) => {
        if (!acc[disease.disease_id]) {
          acc[disease.disease_id] = disease
        }
      })
      return acc
    }, {})

    const uniqueDiseasesArray = Object.values(uniqueDiseases)
    return uniqueDiseasesArray
  }

  const generateReport = () => {
    // return console.log('totalFetchedDiseases', totalFetchedDiseases)
    setReportLoading(true)
    // console.log('totalFetchedDiseases', totalFetchedDiseases)
    const uniqueDiseases = getUniqueDiseases(totalFetchedDiseases)

    //for each item in totalFetchedDiseases, fetch the disease info using the ViewDisease function and append the disease info to the totalFetchedDiseases array item

    uniqueDiseases.forEach(async (disease) => {
      // console.log('disease', disease)
      const diseaseInfo = await viewDisease(disease)

      disease.diseaseInfo = diseaseInfo
    })
    // console.log('totalFetchedDiseasesClone', totalFetchedDiseasesClone)
    setTimeout(() => {
      console.log('unique', uniqueDiseases)
      // setTotalFetchedDiseasesWithInfo(uniqueDiseases)
      setReportLoading(false)

      navigate('/report', {
        state: {
          totalFetchedDiseases,
          totalFetchedDiseasesWithInfo: uniqueDiseases,
        },
      })
    }, 3000)
  }

  //create trip function

  const viewDisease = async (disease) => {
    // setModalOpen(true)
    // setSectionsLoading(true)
    // setSections([])

    var dataToSend = {
      disease: {
        id: disease.disease_id,
      },
    }

    try {
      const response = await fetch(
        process.env.REACT_APP_MAIN_URL + '?diseasesInfo=d',
        {
          method: 'POST',
          headers: new Headers({
            Authorization: 'Bearer ' + process.env.REACT_APP_MAIN_TOKEN,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify(dataToSend),
        },
      )

      const responseJson = await response.json()

      if (responseJson.length) {
        console.log('responsejson->', responseJson)
        return responseJson
      } else {
        return []
      }
    } catch (error) {
      console.log('error', error)
      return []
    }
  }

  const processSearchResults = (r) => {
    console.log('r is', r.text)
    setCurrentCoordinates([r.latlng.lat, r.latlng.lng])
    // const country
    const country = r.results[0].properties.Country
    setCountry(country)
    setPlace(r.text)
    //add r.text to trips array
    // const tripsClone = [...trips]
    // tripsClone.push(r.text)
    // console.log('tripsClone', tripsClone)
    // setTrips(tripsClone)

    // setFetchDiseases([])
    fetchDiseaseArray(
      r.latlng.lng,
      r.latlng.lat,
      country,
      setFetchDiseases,
      setSearchFinished,
      r.text,
    )

    // console.log(fetchDiseases)
  }
  const handlePrint = () => {
    if (modalRef.current) {
      const content = modalRef.current.innerHTML
      const originalContents = document.body.innerHTML
      document.body.innerHTML = content
      window.print()
      document.body.innerHTML = originalContents
      document.body.style.pointerEvents = 'auto'
      // window.onafterprint = () => {
      //
      // }
    }
    // if (modalRef.current) {
    //   const content = modalRef.current.innerHTML

    //   document.body.innerHTML = content
    //   window.print()

    //   // Reset body content after print dialog is closed or canceled
    //   window.onafterprint = () => {
    //     document.body.style.pointerEvents = 'auto'
    //   }
    //   // setTimeout(() => {
    //   //   document.body.innerHTML = originalContents
    //   // }, 100)
    // }
  }

  const handleConvertToPDF = () => {
    if (modalRef.current) {
      const modalContent = modalRef.current

      // Use html2canvas to convert the modal content to an image
      html2canvas(modalContent).then((canvas) => {
        const imgData = canvas.toDataURL('image/png')

        // Calculate the width and height of the PDF page
        const pdfWidth = 210 // A4 width in mm
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width

        // Create a new jsPDF instance
        const pdf = new jsPDF('p', 'mm', 'a4')

        // Add the image to the PDF
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)

        //if canvas height is greater than a4 page size then add new page
        // if (canvas.height > pdfHeight) {
        //   pdf.addPage()
        //   pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)
        // }

        // Save the PDF
        pdf.save('mytrip.pdf')
      })
    }
  }

  return (
    <>
      {/* <Header /> */}
      <Row className="mt-5">
        <Col md={8}>
          {currentView === 'addStops' && (
            <Card>
              <Card.Header>
                <Card.Title as="h5">
                  <i
                    className="fa fa-arrow-left"
                    onClick={() => navigate('/')}
                  />{' '}
                  &nbsp; &nbsp; Search for a place on your trip
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <MapContainer
                  id="mapId"
                  zoom={2}
                  minZoom={2}
                  center={[39.759, -88.157]}
                  style={{ height: 280, width: '70%' }}
                  whenCreated={setThisMap}
                  zoomControl={false}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url={accessUrl}
                    maxZoom={18}
                    subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                  />

                  <EsriLeafletGeoSearch
                    useMapBounds={false}
                    position="topleft"
                    expanded
                    eventHandlers={{
                      results: (r) => processSearchResults(r),
                    }}
                  />
                  {currentCoordinates.length > 0 && (
                    <CustomMarker
                      map={thisMap}
                      data={{
                        position: currentCoordinates || [20.27, -156],
                        diseases: [],
                        searchState: searchFinished,
                      }}
                      isActive
                      pinDrop
                    />
                  )}
                </MapContainer>
              </Card.Body>
            </Card>
          )}
        </Col>
        <Col md={4}>
          <h4>Trips</h4>
          <ListGroupItem>
            {trips.map((trip, index) => (
              <ListGroup.Item key={index}>
                <p>{trip}</p>
              </ListGroup.Item>
            ))}
          </ListGroupItem>
          {totalFetchedDiseases?.length > 0 && (
            <button
              className="btn btn-primary"
              onClick={generateReport}
              disabled={reportLoading}
            >
              Generate report{' '}
              {reportLoading && <i className="fa fa-spinner fa-spin" />}
            </button>
          )}
        </Col>
      </Row>
      <BottomNavigation />
      <Modal show={showReport} onHide={() => setShowReport(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Report about Your trip</Modal.Title>
        </Modal.Header>
        <Modal.Body ref={modalRef}>
          <Row>
            <h3>Disease Advisor Trip Report</h3>
            <Col md={8}>
              <ListGroup>
                {/* places visited */}
                <h5>Places to visit</h5>
                <ListGroup.Item>
                  {totalFetchedDiseases?.map((fetched) => {
                    return (
                      <ListGroup.Item>
                        <p>{fetched.placeName}</p>
                        <ListGroup.Item>
                          <p>
                            {fetched.diseasesArray.map((disease) => {
                              return <p>{disease.diseasename}</p>
                            })}
                          </p>
                        </ListGroup.Item>
                      </ListGroup.Item>
                    )
                  })}
                </ListGroup.Item>
              </ListGroup>
            </Col>

            <Col md={12}>
              <Card>
                <Card.Header>
                  <Card.Title as="h5">Detailed Disease Risk</Card.Title>
                </Card.Header>
                <Card.Body>
                  {/* Represent the diseaseInfo in totalFetchedDiseases array elements */}
                  {totalFetchedDiseasesWithInfo.map((disease, index) => {
                    if (disease.diseaseInfo?.length > 0) {
                      return (
                        <Card key={index}>
                          <Card.Header>
                            <Card.Title as="h5">
                              {disease.diseasename}
                            </Card.Title>
                          </Card.Header>
                          <Card.Body>
                            {disease.diseaseInfo?.map((section, index) => (
                              <Accordion key={index} defaultActiveKey={index}>
                                <Accordion.Item eventKey={index}>
                                  <Accordion.Header>
                                    <span
                                      style={{
                                        backgroundColor: '#e7f1ff',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {section.title}
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Body open>
                                    {section.content}
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            ))}
                          </Card.Body>
                        </Card>
                      )
                    } else {
                      return null
                    }
                  })}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex flex-row justify-content-around p-2">
            <Button variant="secondary" onClick={handleConvertToPDF}>
              <i className="fa fa-print" />
              PDF
            </Button>
            <form className="d-flex flex-row justify-content-around p-2">
              <input
                type="email"
                placeholder="Email"
                className="form-control mr-5"
              />
              <Button variant="secondary" onClick={() => setModalOpen(false)}>
                Share
              </Button>
            </form>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default PlanTripScreen
