import React, { useEffect, useRef, useState } from 'react'
import {
  MapContainer,
  LayersControl,
  TileLayer,
  ZoomControl,
  Marker,
  Popup,
  useMapEvents,
} from 'react-leaflet'
import L from 'leaflet'

import EsriLeafletGeoSearch from 'react-esri-leaflet/plugins/EsriLeafletGeoSearch'
import { Accordion, Button, Card, Modal, Nav, NavItem } from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom'
import './css/staleMapContainer.css'
import DiseaseIcon from '../components/utilities/DiseaseIcon'
import CustomMarker from '../components/utilities/CustomMarker'
import { fetchDiseaseArray } from '../functions'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser, login, logout } from '../redux_setup/slices/userSlice'
import Swal from 'sweetalert2'
import { iso1A2Code } from '@ideditor/country-coder'
import { empty, selectDisease } from '../redux_setup/slices/diseaseSlice'
import useWindowDimensions from '../components/hooks/useDimensions'
import CircularMenu from '../components/common/CircularMenu'
import BottomNavigation from '../components/common/BottomNavigation'
// import codegrid from 'react-codegrid'
// const externalUrl = "https://diseaseadviser.com";

const StaleMapContainer = (props) => {
  const [showMenu, setShowMenu] = useState(false)
  const [currentCoordinates, setCurrentCoordinates] = useState([])
  const [thisMap, setThisMap] = useState()
  const [fetchDiseases, setFetchDiseases] = useState([])
  const [searchFinished, setSearchFinished] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [sections, setSections] = useState([])
  const [sectionsLoading, setSectionsLoading] = useState(false)
  const [dis, setDis] = useState('')
  const [currentLocation, setCurrentLocation] = useState({
    latitude: 39.759,
    longitude: -88.157,
  })
  const [permissionStatus, setPermissionStatus] = useState('pending')
  const user = useSelector(selectUser)
  const disease = useSelector(selectDisease)
  const dispatch = useDispatch()
  const logoutHandler = () => {
    Swal.fire({
      title: 'Are you sure you wish to logout?',
      showCancelButton: true,
      icon: 'info',
      width: '600',
      confirmButtonText: 'Yes Logout',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(logout())
        localStorage.setItem('temp_disease_adv_token', 'null')
      } else {
        console.log('cancelled')
        // navigate('/')
      }
    })
  }
  const { height, width } = useWindowDimensions()
  // let accessUrl = 'https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
  let accessUrl = 'http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}'

  useEffect(() => {
    // const getLocation = async () => {
    //   try {
    //     const { coords } = await navigator.geolocation.getCurrentPosition()
    //     const { latitude, longitude } = coords
    //     setCurrentLocation({ latitude, longitude })
    //     setPermissionStatus('granted')
    //   } catch (error) {
    //     console.error('Error getting current location:', error.message)
    //     setPermissionStatus('denied')
    //   }
    // }

    // // Check if the Geolocation API is available in the browser
    // if ('geolocation' in navigator) {
    //   getLocation()
    // } else {
    //   console.error('Geolocation is not supported in this browser.')
    //   setPermissionStatus('denied')
    // }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error)
    } else {
      console.log('Geolocation not supported')
    }

    function success(position) {
      const latitude = position.coords.latitude
      const longitude = position.coords.longitude
      console.log(`Latitude: ${latitude}, Longitude: ${longitude}`)
      setCurrentLocation({ latitude, longitude })
    }

    function error() {
      console.log('Unable to retrieve your location')
    }
  }, []) // The empty dependency array ensures that this effect runs only once on component mount

  console.log('currentLocation', currentLocation)
  return (
    <>
      <MapContainer
        id="mapId"
        zoom={10}
        minZoom={4}
        center={[currentLocation?.latitude, currentLocation?.longitude]}
        style={{ height: height }}
        zoomControl={false}
        whenCreated={setThisMap}
        key={currentLocation?.latitude}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          url={accessUrl}
          maxZoom={18}
          noWrap={true}
          // tileSize={1024}
          // id="mapbox/streets-v11"
          // zoomOffset={-2}
          // accessToken={mapboxToken}
          subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
        />
      </MapContainer>
      <div className="landing-container">{props.children}</div>
    </>
  )
}

export default StaleMapContainer
