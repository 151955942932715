import { createSlice } from '@reduxjs/toolkit' //importing create slice

// define initialState

const initialState = {
  disease: null,
}

// function to export
const diseaseSlice = createSlice({
  name: 'diseaseInfo',
  initialState,
  reducers: {
    populate: (state, action) => {
      state.disease = action.payload
    },
    empty: (state) => {
      state.disease = null
    },
  },
})

export const { populate, empty } = diseaseSlice.actions

// selectors
export const selectDisease = (state) => state.diseaseInfo.disease

export default diseaseSlice.reducer
