import React, { useEffect, useState } from 'react'
import Header from '../components/common/Header'
import {
  Row,
  Col,
  Badge,
  ListGroup,
  Card,
  Button,
  Form,
  Accordion,
  Table,
} from 'react-bootstrap'
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet'
import { useLocation } from 'react-router-dom'
import Iframe from '../components/utilities/Iframe'

const RiskAnalysisScreen = () => {
  const location = useLocation()
  const [diseaseToView, setDiseaseToView] = useState('')
  const [sections, setSections] = useState([])
  const [sectionsLoading, setSectionsLoading] = useState(false)
  const [diseases, setDiseases] = useState([])
  const [feedsLoading, setFeedsLoading] = useState(true)

  let travelAnalysisUrlInitial =
    'https://maps.diseaseadviser.com:8443/travel_analysis-web.php'
  let tempUrl = `${travelAnalysisUrlInitial}?plan=${location.state.links}`
  const iframe = `<iframe  style="width: 100%;height:60vh" scrolling="no" title="fx." src=${tempUrl} frameborder="no" allowtransparency="true" allowfullscreen="true"></iframe>`

  useEffect(() => {
    var dataToSend = {
      travel: {
        id: location.state.links,
      },
    }

    fetch(process.env.REACT_APP_MAIN_URL + '?countrylevelAnalysis=1', {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + process.env.REACT_APP_MAIN_TOKEN,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        //no longer loading
        setFeedsLoading(false)
        if (responseJson.length > 0) {
          setDiseases(responseJson)
        }
        //no longer fetching
        // SetIsFetching(false);
      })
      .catch((error) => {
        setFeedsLoading(false)
        console.log(error.message)
      }) //to catch the errors if any
  }, [])

  const viewDisease = (disease) => {
    setDiseaseToView(disease.name)
    setSectionsLoading(true)
    setSections([])

    var dataToSend = {
      disease: {
        id: disease.id,
      },
    }

    fetch(process.env.REACT_APP_MAIN_URL + '?diseasesInfo=d', {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + process.env.REACT_APP_MAIN_TOKEN,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        //no longer loading
        setSectionsLoading(false)
        if (responseJson.length) {
          setSections(responseJson)
        }
        //no longer fetching
        // SetIsFetching(false);
      })
      .catch((error) => {
        setSectionsLoading(false)
      }) //to catch the errors if any
  }

  function receiveMessage(e) {
    // Get the sent data

    if (e.origin === 'https://maps.diseaseadviser.com') {
      const data = e.data
      // return console.log(e)
      if (typeof data === 'string') {
        try {
          const decoded = decodeURIComponent(data)
          const item = JSON.parse(decoded)
          // console.log(item)
          viewDisease({ id: item.disease_id, name: item.diseasename })
        } catch (e) {
          console.log(e)
        }
      }
    }

    // If you encode the message in JSON before sending them,
    // then decode here
    // const decoded = JSON.parse(data);
  }

  // window.onmessage = (event) => {
  //   console.log(event.origin)
  //   console.log(`Received message: ${event.data}`)
  // }

  useEffect(() => {
    window.addEventListener('message', receiveMessage)

    return () => {
      window.removeEventListener('message', receiveMessage)
    }
  }, [])

  return (
    <>
      <Header />
      <Row style={{ marginTop: 30 }}>
        <Col md={12}>
          <Iframe iframe={iframe} allow="autoplay" />
        </Col>
      </Row>
      <Row className="mt-2 ">
        <h5>Diseases present at country level</h5>{' '}
        <Col md={6}>
          <Card className="fixed-view">
            <Card.Header>
              <Card.Title as="h5">
                Info {diseaseToView && ` about ${diseaseToView}`}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              {sectionsLoading ? (
                <div className="alert alert-info">Loading...</div>
              ) : (
                <>
                  {sections.length > 0 ? (
                    <Accordion defaultActiveKey="0">
                      {sections.map((section, index) => (
                        <Accordion.Item eventKey={index} key={index}>
                          <Accordion.Header>
                            <span
                              style={{
                                backgroundColor: '#e7f1ff',
                                fontWeight: 'bold',
                              }}
                            >
                              {section.title}
                            </span>
                          </Accordion.Header>
                          <Accordion.Body>{section.content}</Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  ) : (
                    <div className="alert alert-info text-center">
                      Nothing to display
                    </div>
                  )}
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <div className="fixed-view">
            <Table striped="columns" hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Disease</th>
                  <th>Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {feedsLoading ? (
                  <div className="alert alert-info"> Loading...</div>
                ) : (
                  <>
                    {diseases.map((disease, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{disease.name}</td>
                        <td>
                          <i className="fa fa-virus" />
                        </td>
                        <td>
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => viewDisease(disease)}
                          >
                            <i className="fa fa-eye " />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default RiskAnalysisScreen
