import React from 'react'

const Loading = () => {
  return (
    <div>
      <i className=" content-centered fa fa-spinner fa-spin fa-4x text-primary" />
    </div>
  )
}

export default Loading
