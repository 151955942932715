import React, { useEffect, useState } from 'react'
import Header from '../../components/common/Header'
import { Row, Col, Badge, ListGroup, Card, Button, Form } from 'react-bootstrap'
import {
  MapContainer,
  LayersControl,
  TileLayer,
  useMapEvents,
} from 'react-leaflet'
import '../css/planTrip.css'

import EsriLeafletGeoSearch from 'react-esri-leaflet/plugins/EsriLeafletGeoSearch'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser, login, logout } from '../../redux_setup/slices/userSlice'
import { redirectToCheckout } from '../../functions/stripe'

const Payments = () => {
  let navigate = useNavigate()
  toast.configure()
  const user = useSelector(selectUser)
  const [selectedPlan, setSelectedPlan] = useState('')
  const [status, setStatus] = useState('')

  const url = process.env.REACT_APP_MAIN_URL
  const plans_dev = [
    {
      id: 'price_1LrrYRK0ENw8dXdk3fVCUhtb',
      price: 4.99,
      duration: 'Monthly',
    },
    {
      id: 'price_1LrrtrK0ENw8dXdku0LKRTwr',
      price: 13.99,
      duration: 'Quarterly',
    },
    {
      id: 'price_1LrrwLK0ENw8dXdkVKCibwRA',
      price: 25.99,
      duration: '6 Months',
    },
    {
      id: 'price_1LrryHK0ENw8dXdkVBZbRHPE',
      price: 49.99,
      duration: 'Yearly',
    },
  ]

  const plans = [
    {
      id: 'price_1LsDApK0ENw8dXdk2OBj5Nbp',
      price: 4.99,
      duration: 'Monthly',
    },
    {
      id: 'price_1LsDAhK0ENw8dXdkmqAHlli9',
      price: 13.99,
      duration: 'Quarterly',
    },
    {
      id: 'price_1LsDAYK0ENw8dXdk5baCGixO',
      price: 25.99,
      duration: '6 Months',
    },
    {
      id: 'price_1LsD9zK0ENw8dXdkr4jtJSPz',
      price: 49.99,
      duration: 'Yearly',
    },
  ]

  const toStripe = () => {
    setStatus('Preparing your payment')
    const dataToSend = {
      user_id: user.user_id,
      plan: selectedPlan,
    }
    fetch(
      process.env.REACT_APP_STRIPE_URL + '/api/stripe/create_app_session/',
      {
        method: 'POST',
        headers: new Headers({
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(dataToSend),
      },
    )
      .then((response) => response.json())
      .then((res) => {
        console.log(res)
        //Hide Loader
        // dismissLoader()
        if (res.error) {
          setStatus('')
          return toast.warning(res.error, {
            position: toast.POSITION.TOP_CENTER,
          })
        }
        return window.location.assign(res.url)
      })
      .catch((error) => {
        console.log(error)
        setStatus('')
        //Hide Loader
        // dismissLoader()
        // alert(error.message)
        return toast.warning('An error occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
        })
      })
  }

  return (
    <>
      <Header />
      <Row className="mt-5">
        <Col md={8} className="mt-2">
          <Card>
            <Card.Header>
              <Card.Title as="h5">Go Pro</Card.Title>
            </Card.Header>
            <Card.Body>
              <h6>You tried to access a premium page.</h6>
              <p>Upgrade to pro to access the following</p>
              <ul>
                <li>Discover premium diseases</li>
                <li>Plan for trips</li>
                <li>View your disease risk trip analysis</li>
                <li>
                  Access info about disease-safe business premises around you
                </li>
              </ul>
              <h4>Choose a plan</h4>
              <Row>
                {plans.map((pln) => (
                  <Col md={3} sm={6} key={pln.id}>
                    <Card
                      className={`plan-cat ${
                        selectedPlan == pln.id && 'plan-selected'
                      }`}
                      onClick={() => setSelectedPlan(pln.id)}
                    >
                      <Card.Header>
                        <Card.Title as="h5">{pln.duration}</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <p>{pln.price} $</p>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
              <Button
                variant="primary"
                onClick={toStripe}
                disabled={!selectedPlan}
              >
                Upgrade Now
              </Button>
              <span className="ml-1 text-info">
                {status} {status && <i className="fa fa-spin fa-spinner" />}
              </span>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Payments
