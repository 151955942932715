import React, { useCallback, useEffect, useState } from 'react'
import Header from '../components/common/Header'
import {
  Row,
  Col,
  Button,
  Card,
  InputGroup,
  Form,
  ListGroup,
  Container,
  Table,
  Accordion,
} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import BottomNavigation from '../components/common/BottomNavigation'

const amenityUrl = `https://www.google.com/maps/search/`

const DictionaryScreen = () => {
  const [diseases, setDiseases] = useState([])
  const [feedsLoading, setFeedsLoading] = useState(true)
  const [diseaseToView, setDiseaseToView] = useState('')
  const [sections, setSections] = useState([])
  const [sectionsLoading, setSectionsLoading] = useState(false)
  const [filteredData, setFilteredData] = useState('')
  const [selectedDiseases, setSelectedDiseases] = useState([])
  const url = process.env.REACT_APP_MAIN_URL

  useEffect(() => {
    //code for unsubscribing
    const abortcontroller = new AbortController()
    const signal = abortcontroller.signal

    fetch(url + '?diseases=1', {
      signal: signal,
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + process.env.REACT_APP_MAIN_TOKEN,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        //no longer loading
        setFeedsLoading(false)
        if (responseJson.length > 0) {
          setDiseases(responseJson)
        }
      })
      .catch((error) => {
        setFeedsLoading(false)
      }) //to catch the errors if any

    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])

  useEffect(() => {
    if (filteredData.length > 0) {
      setSelectedDiseases(filteredData)
    } else {
      setSelectedDiseases(diseases)
    }
  }, [diseases, filteredData])

  const viewDisease = (disease) => {
    setDiseaseToView(disease.name)
    setSectionsLoading(true)
    setSections([])

    var dataToSend = {
      disease: {
        id: disease.id,
      },
    }

    fetch(url + '?diseasesInfo=d', {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + process.env.REACT_APP_MAIN_TOKEN,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        //no longer loading
        setSectionsLoading(false)
        if (responseJson.length) {
          setSections(responseJson)
          // SetNoData(false);
        }
        //no longer fetching
        // SetIsFetching(false);
      })
      .catch((error) => {
        setSectionsLoading(false)

        // SetNoData(true);
        // if (noData && !isFetching) {
        // alert(error.message);
        // }

        // SetIsFetching(false);
      }) //to catch the errors if any
  }

  const debounce = (func) => {
    let timer
    return function (...args) {
      const context = this
      if (timer) clearTimeout(timer)
      timer = setTimeout(() => {
        timer = null
        func.apply(context, args)
      }, 500)
    }
  }

  const handleChange = (text) => {
    // console.log(text)
    let fd = diseases.filter(function (item) {
      return item.name.toLowerCase().includes(text.toLowerCase())
    })
    // console.log(fd, diseases)

    // this.setState({filteredData: filteredData});
    setFilteredData(fd)
  }

  const optimizedFn = debounce(handleChange)
  const navigate = useNavigate()

  return (
    <>
      {/* <Header /> */}

      <Container>
        <Row className="p-2" style={{ marginTop: 60 }}>
          <Card className="m-3">
            <Card.Header>
              <Card.Title as="h5">
                <i className="fa fa-arrow-left" onClick={() => navigate('/')} />{' '}
                &nbsp; &nbsp; Disease Database
              </Card.Title>
            </Card.Header>
          </Card>
          <Col md={6}>
            <InputGroup size="lg">
              <Form.Control
                aria-label="Large"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Search here.."
                onChange={(e) => optimizedFn(e.target.value)}
              />
              <InputGroup.Text id="inputGroup-sizing-lg">
                <i className="fa fa-search" />
              </InputGroup.Text>
            </InputGroup>
          </Col>
          <Row className="mt-2 ">
            <Col md={6}>
              <div className="fixed-view">
                <Table striped="columns" hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Disease</th>
                      <th>Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {feedsLoading ? (
                      <div className="alert alert-info"> Loading...</div>
                    ) : (
                      <>
                        {selectedDiseases.map((disease, i) => (
                          <tr
                            key={i}
                            className="disease-row"
                            onClick={() => viewDisease(disease)}
                          >
                            <td>{i + 1}</td>
                            <td>{disease.name}</td>
                            <td>
                              <i className="fa fa-virus" />
                            </td>
                            <td>
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => viewDisease(disease)}
                              >
                                <i className="fa fa-eye " />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col md={6}>
              <Card className="fixed-view">
                <Card.Header>
                  <Card.Title as="h5">
                    Info {diseaseToView && ` about ${diseaseToView}`}
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  {sectionsLoading ? (
                    <div className="alert alert-info">Loading...</div>
                  ) : (
                    <>
                      {sections.length > 0 ? (
                        <Accordion defaultActiveKey="0">
                          {sections.map((section, index) => (
                            <Accordion.Item eventKey={index} key={index}>
                              <Accordion.Header>
                                <span
                                  style={{
                                    backgroundColor: '#e7f1ff',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {section.title}
                                </span>
                              </Accordion.Header>
                              <Accordion.Body>{section.content}</Accordion.Body>
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      ) : (
                        <div className="alert alert-info text-center">
                          Nothing to display
                        </div>
                      )}
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Row>
      </Container>
      <BottomNavigation />
    </>
  )
}

export default DictionaryScreen
