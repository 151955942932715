import React, { useEffect, useState } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import BusinessesScreen from './screens/BusinessesScreen'
import ForgotPassword from './screens/common/ForgotPassword'
import Login from './screens/common/Login'
import Register from './screens/common/Register'
import DictionaryScreen from './screens/DictionaryScreen'
import HomeScreen from './screens/HomeScreen'
import OutbreaksScreen from './screens/OutbreaksScreen'
import PlanTripScreen from './screens/PlanTripScreen'
import RiskAnalysisScreen from './screens/RiskAnalysisScreen'

import { useSelector, useDispatch } from 'react-redux'
import { selectUser, login, logout } from './redux_setup/slices/userSlice'
import './styles.css'
import { queryStringToObject } from './functions'
import Loading from './screens/common/Loading'
import Payments from './screens/common/Payments'
import Success from './screens/common/Success'
import ReportScreen from './screens/ReportScreen'
import Landing from './screens/Landing'
import StripePre from './screens/StripePre'
import Account from './screens/Account'

export default function App() {
  const [checking, setChecking] = useState(true)

  const user = useSelector(selectUser)
  const dispatch = useDispatch()
  const checkSubscription = async (email) => {
    const response = await fetch(
      `${process.env.REACT_APP_STRIPE_PAYMENTS_URL}api/stripe/check-subscription`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
        }),
      },
    )

    return response
  }
  const tempToken = localStorage.getItem('temp_disease_adv_token')
  console.log('user token', tempToken)
  // const decodedToken = {
  //   name: 'Gustone Alwanga',
  //   token: 'DEXCREWD',
  //   id: 12,
  // }
  const decodedToken = queryStringToObject(tempToken)
  useEffect(() => {
    // const suta = new URLSearchParams(decodedToken).toString()
    // console.log(suta)

    if (tempToken && tempToken !== 'null' && tempToken !== 'undefined') {
      dispatch(
        login({
          userName: decodedToken.name,

          user_id: decodedToken.id,
          token: decodedToken.token,
          user_type: decodedToken.user_type || 1,
          email: decodedToken.email,
        }),
      )
      setChecking(false)
    } else {
      dispatch(logout())
      setChecking(false)
    }
  }, [])
  //check Stripe subscription
  useEffect(() => {
    if (tempToken && tempToken !== 'null' && tempToken !== 'undefined') {
      console.log('checking subscription')
      checkSubscription(decodedToken.email)
        .then((response) => response.json())
        .then((responseJson) => {
          // console.log('subscription responseJson', responseJson)
          if (responseJson?.code === 'VALID_SUBSCRIPTION') {
            //do nothing
          } else {
            console.log('marking user as free', responseJson?.code)
            //mark user as free
            dispatch(
              login({
                userName: decodedToken.name,

                user_id: decodedToken.id,
                token: decodedToken.token,
                email: decodedToken.email,
                user_type: 3,
              }),
            )

            const toStore = {
              userName: decodedToken.name,

              user_id: decodedToken.id,
              token: decodedToken.token,
              email: decodedToken.email,
              user_type: 3,
            }

            localStorage.setItem(
              'temp_disease_adv_token',
              new URLSearchParams(toStore).toString(),
            )
          }
        })
        .catch((e) => console.log('subscription error', e))
    } else {
      console.log('user is logged out', user)
    }
  }, [])

  return (
    <div className="App">
      {checking ? (
        <Loading />
      ) : (
        <>
          {!!user ? (
            <>
              {user?.user_type != 2 ? (
                <Routes>
                  <Route index element={<StripePre />} />
                  <Route path="subscribe" element={<StripePre />} />
                  <Route path="*" element={<StripePre />} />
                </Routes>
              ) : (
                <Routes>
                  <Route index element={<HomeScreen />} />
                  <Route path="plan-trip" element={<PlanTripScreen />} />
                  <Route path="success" element={<Success />} />

                  <Route path="payments" element={<Payments />} />
                  <Route path="businesses" element={<BusinessesScreen />} />
                  <Route path="diseases" element={<DictionaryScreen />} />
                  <Route path="report" element={<ReportScreen />} />
                  <Route
                    path="risk-analysis"
                    element={<RiskAnalysisScreen />}
                  />
                  <Route path="landing" element={<Landing />} />
                  <Route path="account" element={<Account />} />
                  <Route path="*" element={<HomeScreen />} />
                  {/* <Route path=":teamId" element={<Team />} /> */}
                </Routes>
              )}
            </>
          ) : (
            <Routes>
              <Route index element={<Landing />} />
              <Route path="login" element={<Login />} />
              {/* <Route path="subscribe" element={<StripePre />} /> */}
              <Route path="register" element={<Register />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="*" element={<Landing />} />
            </Routes>
          )}
        </>
      )}
    </div>
  )
}
