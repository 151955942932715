import React, { useState } from 'react'

import { Accordion, Button, Card, Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import './css/homestyles.css'
import BottomNavigation from '../components/common/BottomNavigation'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser, login, logout } from '../redux_setup/slices/userSlice'
import Swal from 'sweetalert2'

const Account = (props) => {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showChangePassword, setShowChangePassword] = useState(false)

  const changePassword = () => {
    if (password !== confirmPassword || !password) {
      return alert('Please enter valid matching passwords')
    }
    var dataToSend = {
      user: {
        email: user?.email,
        password: password,
      },
    }

    fetch(process.env.REACT_APP_MAIN_URL + 'index.php?resetPasswordFinal=d', {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + process.env.REACT_APP_MAIN_TOKEN,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log('responseJSON', responseJson)
        //no longer loading
        // setSectionsLoading(false)
        if (responseJson?.[0]?.response == 'proceed') {
          // setSections(responseJson)
          // SetNoData(false);
          alert('You have successfully updated your password')
        } else {
          alert(
            'No change has been made to your password. Please ensure you are entering a new password',
          )
        }
        //no longer fetching
        // SetIsFetching(false);
      })
      .catch((error) => {
        console.log('error', error)
        alert('An error occured while updating your password')
        // setSectionsLoading(false)

        // SetNoData(true);
        // if (noData && !isFetching) {
        // alert(error.message);
        // }

        // SetIsFetching(false);
      }) //to catch the errors if any
  }
  console.log('user', user)
  const navigate = useNavigate()
  const logoutHandler = () => {
    Swal.fire({
      title: 'Are you sure you wish to logout?',
      showCancelButton: true,
      icon: 'info',
      width: '600',
      confirmButtonText: 'Yes Logout',
      confirmButtonColor: 'blue',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(logout())
        localStorage.setItem('temp_disease_adv_token', 'null')
      } else {
        console.log('cancelled')
        // navigate('/')
      }
    })
  }

  return (
    <>
      <Card>
        <Card.Header className="text-center">
          <Card.Title as="h5">Account</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="row my-2">
            <div className="col-md-6 col-12">
              <ul className="list-group">
                <li className="list-group-item">User Name: {user.userName}</li>
                <li className="list-group-item">
                  User Type:
                  {user.user_type === '2' ? (
                    <span className="badge bg-success ms-2">Premium</span>
                  ) : (
                    <span className="badge bg-secondary ms-2">Freemium</span>
                  )}
                </li>
                <li className="list-group-item">Email: {user.email}</li>
              </ul>
              <div className="d-flex flex-row justify-content-around mt-3">
                <button
                  className="landing-button"
                  onClick={() => setShowChangePassword(!showChangePassword)}
                >
                  {showChangePassword ? 'Cancel' : 'Change Password'}
                </button>
                <button className="landing-button" onClick={logoutHandler}>
                  Logout
                </button>
              </div>
            </div>
          </div>
          {showChangePassword && (
            <div className="row my-2">
              <div className="col-md-6 col-12">
                <h4>Change Password</h4>
                <hr />
                <Form.Group className="mb-3">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="New Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Confirm New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Form.Group>
                <Button
                  variant="warning"
                  type="button"
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
                <Button
                  variant="primary"
                  className="float-end"
                  type="submit"
                  onClick={changePassword}
                >
                  Submit
                </Button>
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
      <BottomNavigation />
    </>
  )
}

export default Account
