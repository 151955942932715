import React, { useEffect, useRef, useState } from 'react'
import { ListGroup } from 'react-bootstrap'
import { Marker, Popup } from 'react-leaflet'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from '../../redux_setup/slices/userSlice'
import { populate } from '../../redux_setup/slices/diseaseSlice'
import DiseaseIcon from './DiseaseIcon'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom'

const CustomMarker = ({ isActive, data, map, pinDrop }) => {
  const [refReady, setRefReady] = useState(false)
  let popupRef = useRef()
  const user = useSelector(selectUser)
  const dispatch = useDispatch()
  toast.configure()
  const navigate = useNavigate()

  useEffect(() => {
    // console.log('userrrrr', user)
    if ((refReady && isActive) || (data.diseases.length > 0 && isActive)) {
      popupRef.openOn(map)
    }
  }, [isActive, refReady, map, data.diseases])

  const moreAbout = (disease) => {
    console.log(disease)
    dispatch(populate(disease))
  }
  const toastInfo = () => {
    toast.info('This is a premium disease. Upgrade to pro to view', {
      position: toast.POSITION.BOTTOM_RIGHT,
    })
    return navigate('/payments')
  }

  return (
    <Marker position={[data.position[0], data.position[1]]}>
      <Popup
        closeOnClick={false}
        zoomAnimation
        ref={(r) => {
          popupRef = r
          setRefReady(true)
        }}
      >
        {data.diseases.length > 0 ? (
          <div className="alert alert-info">
            <h5>This area is at risk of:</h5>
            <ListGroup>
              {data.diseases.map((disease, index) => (
                <ListGroup.Item key={index}>
                  {disease.cost_type === 'Free' ? (
                    <span onClick={() => moreAbout(disease)} role="button">
                      {disease.diseasename}
                    </span>
                  ) : (
                    <>
                      {user?.user_type == 2 ? (
                        <span onClick={() => moreAbout(disease)} role="button">
                          {disease.diseasename}
                        </span>
                      ) : (
                        <span
                          className="text-warning"
                          onClick={toastInfo}
                          role="button"
                        >
                          <i>Premium Disease</i>
                        </span>
                      )}
                    </>
                  )}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        ) : (
          <>
            {pinDrop ? (
              <span>Location Added</span>
            ) : (
              <>
                {data.searchState ? (
                  <span>
                    <i className="fa fa-exclamation-circle text-warning" /> No
                    diseases in location
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-spinner fa-spin" /> Searching for
                    diseases in location
                  </span>
                )}
              </>
            )}
          </>
        )}
      </Popup>
    </Marker>
  )
}

export default CustomMarker
