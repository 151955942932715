import { configureStore } from '@reduxjs/toolkit'
import userReducer from './slices/userSlice'
import diseaseReducer from './slices/diseaseSlice'
import dashboardReducer from './slices/dashboardSlice'
import receptionReducer from './slices/receptionistSlice'

export const store = configureStore({
  reducer: {
    userInfo: userReducer,
    diseaseInfo: diseaseReducer,
    dashboard: dashboardReducer,
    reception: receptionReducer,
  },
})
