import L from 'leaflet'
import leafletPip from 'leaflet-pip'
var mainUrl = process.env.REACT_APP_MAIN_URL
const token = process.env.REACT_APP_MAPS_TOKEN

export const fetchDiseaseArray = (
  lng,
  lat,
  country,
  setter,
  setSearchFinished,
  placeName,
) => {
  const disease_array_url = mainUrl + 'getDiseaseArray'
  // return setter(['thela', 'mshayi', 'sister'])

  //prepare form body string
  const dataToSend = {
    map: {
      lat: lat,
      lng: lng,
      country: country,
    },
  }

  // send request
  fetch(disease_array_url, {
    method: 'POST',
    headers: new Headers({
      Authorization: 'Bearer ' + token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(dataToSend),
  })
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.length) {
        drawPopUp(responseJson, setter, setSearchFinished, placeName)

        // console.log(responseJson)
        // $('#map').css('pointer-events', 'none')
      }
    })
    .catch((error) => console.log(error, 'an error occuured')) //to catch the errors if any
  //finish sending request
}

//function to check accuracy
export const CheckAccuracy = async (file, lat, lng, disease_name) => {
  const f_url = `${mainUrl}geofiles/${file}`
  const response = await fetch(f_url, {
    method: 'GET',
    headers: new Headers({
      Accept: 'application/json',
    }),
  }).catch((error) => {
    console.log('error for ' + file)
    console.log(error)
  }) //to catch the errors if any;
  const data = await response.json()

  const geo = L.geoJson(data) //draw it virtually

  var results = leafletPip.pointInLayer([lng, lat], geo)
  let res
  if (results.length) {
    // console.log(`at ${lat} we have ${disease_name}`);
    res = 1
  } else {
    // console.log("this layer went amiss");
    res = 0
  }
  return res
}

/* function to draw info on markers */
const drawPopUp = async (items, setter, setSearchFinished, placeName) => {
  let sisterArray = []
  items.map((item) => {
    const { lat, lng, country, diseasesArray } = item //destructure the object

    const masterCount = diseasesArray.length
    let count = 0
    if (masterCount == 0) {
      setSearchFinished(true)
    } else {
      diseasesArray.forEach(async function (item) {
        const { diseasename, diseasefile } = item
        const validate = await CheckAccuracy(diseasefile, lat, lng, diseasename)
        // console.log(sisterArray)
        //now we can access the accuracy result, fantastic..

        // if accurate, we add the disease to the message
        if (validate === 1) {
          //item is in disease
          await sisterArray.push(item)
        }
        count++
        if (count === masterCount) {
          // console.log(sisterArray, lat, lng, country)
          console.log('sister array', sisterArray, 'place name', placeName)
          if (!!placeName) {
            const obj = {
              placeName: placeName,
              diseasesArray: sisterArray,
            }
            setter(obj)
          } else {
            setter(sisterArray)
          }

          setSearchFinished(true)
          // plantInfo(sisterArray, lat, lng, country)
        }
      })
    }

    //   });
  })

  // return sisterArray
}
/* end function to draw info on markers */

// draw pop up multiple

export const queryStringToObject = (queryString) => {
  const urlParams = new URLSearchParams(queryString)
  const params = Object.fromEntries(urlParams)
  return params
}

//format date in day month year
export const formatDate = (date, format) => {
  const d = new Date(date)
  if (format === 'd/m/y')
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
  // return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
}
