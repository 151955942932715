import React, { useState } from 'react'
import { Button, Card, Container, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import bg from '../../assets/imgs/background.jpg'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser, login, logout } from '../../redux_setup/slices/userSlice'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
const ForgotPassword = () => {
  const [view, setView] = useState('email')
  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const url = process.env.REACT_APP_MAIN_URL

  toast.configure()

  const fpEmailHandler = () => {
    var dataToSend = {
      user: {
        email: email,
      },
    }
    fetch(url + '?VerifyEmail=1', {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + process.env.REACT_APP_MAIN_TOKEN,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[0].response === 'proceed') {
          setView('code')

          return toast.success(
            'Email verification successful, Code will be sent to email shortly',
            {
              position: toast.POSITION.TOP_CENTER,
            },
          )
        } else if (responseJson[0].response === 'wrong_email') {
          return toast.warning(
            'Please double check to ensure you are entering the correct email',
            {
              position: toast.POSITION.TOP_CENTER,
            },
          )
        } else if (responseJson[0].response === 'send_mail_failed') {
          return toast.warning('Failed to send email', {
            position: toast.POSITION.TOP_CENTER,
          })
        } else {
          return toast.error('An error occured, please try again', {
            position: toast.POSITION.TOP_CENTER,
          })
        }
      })
      .catch((error) => {
        return toast.error(
          'An error occured, please try again ' + error.message,
          {
            position: toast.POSITION.TOP_CENTER,
          },
        )
      })
  }
  const fpCodeHandler = () => {
    var dataToSend = {
      user: {
        email: email,
        code: code,
      },
    }
    fetch(url + '?VerifyCode=1', {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + process.env.REACT_APP_MAIN_TOKEN,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[0].response === 'proceed') {
          setView('password')

          return toast.success('Code has been verified successfully', {
            position: toast.POSITION.TOP_CENTER,
          })
        } else {
          return toast.warning('You entered the wrng code', {
            position: toast.POSITION.TOP_CENTER,
          })
        }
      })
      .catch((error) => {
        return toast.error(
          'An error occured, please try again ' + error.message,
          {
            position: toast.POSITION.TOP_CENTER,
          },
        )
      })
  }

  const fpPasswordHandler = () => {
    var dataToSend = {
      user: {
        email: email,
        password: password,
      },
    }
    fetch(url + '?resetPasswordFinal=1', {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + process.env.REACT_APP_MAIN_TOKEN,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[0].response === 'proceed') {
          return toast.success('You have successfully reset your password', {
            position: toast.POSITION.TOP_CENTER,
          })
        } else {
          return toast.warning('An error occured, please try again', {
            position: toast.POSITION.TOP_CENTER,
          })
        }
      })
      .catch((error) => {
        return toast.error(
          'An error occured, please try again ' + error.message,
          {
            position: toast.POSITION.TOP_CENTER,
          },
        )
      })
  }
  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        height: '100vh',
      }}
      className="overlaid"
    >
      <Container style={{ width: '50%' }} className="content-centered p-2">
        <Card>
          <Card.Header>
            <Card.Title as="h5">Reset your password</Card.Title>
          </Card.Header>
          <Card.Body>
            {view === 'email' && (
              <Form>
                <Form.Group className="mb-3" controlId="formGroupEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGroupPassword">
                  <div className="d-flex flex-row justify-content-between">
                    <Button onClick={fpEmailHandler} disabled={!email}>
                      Continue
                    </Button>
                    <Link to="/login">Back to Login</Link>
                  </div>
                </Form.Group>
              </Form>
            )}
            {view === 'code' && (
              <Form>
                <Form.Group className="mb-3" controlId="formGroupEmail">
                  <Form.Label>Verification Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGroupPassword">
                  <div className="d-flex flex-row justify-content-between">
                    <Button onClick={fpCodeHandler} disabled={!code}>
                      Continue
                    </Button>
                    <Link to="/login">Back to Login</Link>
                  </div>
                </Form.Group>
              </Form>
            )}

            {view === 'password' && (
              <Form>
                <Form.Group className="mb-3" controlId="formGroupPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGroupPassword">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGroupPassword">
                  <div className="d-flex flex-row justify-content-between">
                    <Button
                      onClick={fpPasswordHandler}
                      disabled={!password || password !== confirmPassword}
                    >
                      Continue
                    </Button>
                    <Link to="/login">Back to Login</Link>
                  </div>
                </Form.Group>
              </Form>
            )}
          </Card.Body>
        </Card>
      </Container>
    </div>
  )
}

export default ForgotPassword
