import { loadStripe } from '@stripe/stripe-js'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
  }
  return stripePromise
}
toast.configure()

// const email = localStorage.getItem('temp_disease_adv_scan_connect_email')
const item = {
  price: 'price_1LlrVAK0ENw8dXdkoybfAiaJ',
  quantity: 1,
}

const checkoutOptions = {
  lineItems: [item],
  mode: 'payment',
  successUrl: `${window.location.origin}/success`,
  cancelUrl: `${window.location.origin}/cancel`,
}

export const redirectToCheckout = async () => {
  console.log('redirected to checkout')

  const stripe = await getStripe()
  const { error } = await stripe.redirectToCheckout(checkoutOptions)
}
