import React from 'react'

// Import the circular menu
import {
  CircleMenu,
  CircleMenuItem,
  TooltipPlacement,
} from 'react-circular-menu'

function CircularMenu(props) {
  return (
    <CircleMenu
      startAngle={90}
      rotationAngle={180}
      itemSize={2}
      radius={5}
      /**
       * rotationAngleInclusive (default true)
       * Whether to include the ending angle in rotation because an
       * item at 360deg is the same as an item at 0deg if inclusive.
       * Leave this prop for angles other than 360deg unless otherwise desired.
       */
      rotationAngleInclusive={false}
    >
      {/* <CircleMenuItem
        onClick={() => alert('Clicked the item')}
        tooltip="Email"
        tooltipPlacement={TooltipPlacement.Right}
      >
        <i className="fa fa-book" />
      </CircleMenuItem> */}
      <CircleMenuItem tooltip="Logout" onClick={props.logoutHandler}>
        <i className="fa fa-power-off text-danger" />
      </CircleMenuItem>
      <CircleMenuItem
        tooltip="Account"
        onClick={() => props.navigate('/account')}
      >
        <i className="fa fa-user" />
      </CircleMenuItem>
    </CircleMenu>
  )
}

export default CircularMenu
