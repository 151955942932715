import React, { useEffect, useRef, useState } from 'react'
import Header from '../components/common/Header'
import {
  Row,
  Col,
  Badge,
  ListGroup,
  Card,
  Button,
  Form,
  Modal,
  Accordion,
  ListGroupItem,
  Container,
} from 'react-bootstrap'
import {
  MapContainer,
  LayersControl,
  TileLayer,
  useMapEvents,
} from 'react-leaflet'
import './css/planTrip.css'

import EsriLeafletGeoSearch from 'react-esri-leaflet/plugins/EsriLeafletGeoSearch'
import Swal from 'sweetalert2'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser, login, logout } from '../redux_setup/slices/userSlice'
import CustomMarker from '../components/utilities/CustomMarker'
import { fetchDiseaseArray, formatDate } from '../functions'
import { empty, selectDisease } from '../redux_setup/slices/diseaseSlice'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

const ReportScreen = () => {
  const navigate = useNavigate()

  const [modalOpen, setModalOpen] = useState(false)
  const [email, setEmail] = useState('')
  const [sendingEmail, setSendingEmail] = useState(false)

  const [country, setCountry] = useState('')
  let accessUrl = 'http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}'

  toast.configure()
  const user = useSelector(selectUser)
  const dispatch = useDispatch()
  const location = useLocation()
  const { totalFetchedDiseasesWithInfo, totalFetchedDiseases } = location.state

  const url = process.env.REACT_APP_MAIN_URL
  const modalRef = useRef(null)

  useEffect(() => {
    console.log('location', location)
  }, [])

  const handlePrint = () => {
    window.print()
  }

  const handleConvertToPDF = () => {
    if (modalRef.current) {
      const modalContent = modalRef.current

      // Use html2canvas to convert the modal content to an image
      html2canvas(modalContent).then((canvas) => {
        const imgData = canvas.toDataURL('image/png')

        // Calculate the width and height of the PDF page
        const pdfWidth = 210 // A4 width in mm
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width

        // Create a new jsPDF instance
        const pdf = new jsPDF('p', 'mm', 'a4')

        // Add the image to the PDF
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)

        //if canvas height is greater than a4 page size then add new page
        // if (canvas.height > pdfHeight) {
        //   pdf.addPage()
        //   pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)
        // }

        // Save the PDF
        pdf.save('mytrip.pdf')
      })
    }
  }

  const sendEmail = async () => {
    setSendingEmail(true)
    const placesToVisit = totalFetchedDiseases.map((item) => ({
      placeName: item.placeName,
      diseases: item.diseasesArray.map((disease) => disease.diseasename),
    }))

    const dataToSend = {
      user: {
        email: email,
        placesToVisit: placesToVisit,
        detailedRisk: totalFetchedDiseasesWithInfo,
      },
    }

    try {
      const response = await fetch(
        process.env.REACT_APP_MAIN_URL + 'mail.php?send_mail=d',
        {
          method: 'POST',
          headers: new Headers({
            Authorization: 'Bearer ' + process.env.REACT_APP_MAIN_TOKEN,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify(dataToSend),
        },
      )

      const responseJson = await response.json()
      setSendingEmail(false)
      console.log('tt', responseJson)

      if (responseJson.length > 0) {
        setEmail('')
        return toast.info(responseJson?.[0]?.response, {
          position: toast.POSITION.TOP_CENTER,
        })
      } else {
        return toast.warning('failed to send email', {
          position: toast.POSITION.TOP_CENTER,
        })
      }
    } catch (error) {
      setSendingEmail(false)
      console.log('error', error)
      return toast.warning('failed to send email ' + error?.message, {
        position: toast.POSITION.TOP_CENTER,
      })
    }
  }

  return (
    <div className="container-div">
      <Row className="not-printable">
        <Col md={12}>
          <div className="d-flex flex-row justify-content-around p-2">
            <div className="d-flex flex-row justify-content-around p-1">
              <Button variant="danger" onClick={() => navigate(-1)}>
                <i className="fa fa-chevron-left" />
                Back
              </Button>
              <Button variant="primary" onClick={handlePrint} className="mx-3">
                <i className="fa fa-print" />
                Print
              </Button>
            </div>
            <form className="d-flex flex-row justify-content-around p-2">
              <input
                type="email"
                placeholder="Email"
                className="form-control me-1"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                variant="secondary"
                onClick={sendEmail}
                disabled={sendingEmail}
              >
                <i className="fa fa-share" />
                Share
                {sendingEmail && <i className="fa fa-spin fa-spinner" />}
              </Button>
            </form>
          </div>
        </Col>
      </Row>
      <hr className="not-printable" />
      <Row>
        <Col md={12} className="mb-1 mt-1">
          <Card>
            <Card.Header>
              <div className="d-flex flex-row justify-content-between">
                <div>
                  <img
                    src={require('../assets/imgs/icon.png')}
                    alt="logo"
                    height={100}
                  />
                </div>
                <div className="text-end">
                  <h3>Disease Advisor Trip Report</h3>
                  <p>Report Date: {formatDate(new Date(), 'd/m/y')}</p>
                </div>
              </div>
            </Card.Header>
          </Card>
        </Col>
        <Col md={12} className="mb-1">
          <Card>
            <Card.Header>
              <Card.Title as="h5">Places to visit</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                {totalFetchedDiseases?.map((fetched) => {
                  return (
                    <Col md={6}>
                      <ListGroup.Item>
                        <h5>{fetched.placeName}</h5>
                        <ul>
                          {fetched.diseasesArray.map((disease) => {
                            return <li>{disease.diseasename}</li>
                          })}
                        </ul>
                      </ListGroup.Item>
                    </Col>
                  )
                })}
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col md={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h5">Detailed Disease Risk</Card.Title>
            </Card.Header>
            <Card.Body>
              {/* Represent the diseaseInfo in totalFetchedDiseases array elements */}
              {totalFetchedDiseasesWithInfo.map((disease, index) => {
                if (disease.diseaseInfo?.length > 0) {
                  return (
                    <Card key={index}>
                      <Card.Header>
                        <Card.Title as="h5">{disease.diseasename}</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        {disease.diseaseInfo?.map((section, index) => (
                          <Accordion key={index} defaultActiveKey={index}>
                            <Accordion.Item eventKey={index}>
                              <Accordion.Header>
                                <span
                                  style={{
                                    backgroundColor: '#e7f1ff',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {section.title}
                                </span>
                              </Accordion.Header>
                              <Accordion.Body open>
                                {section.content}
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        ))}
                      </Card.Body>
                    </Card>
                  )
                } else {
                  return null
                }
              })}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default ReportScreen
