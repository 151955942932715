import React from 'react'
import Header from '../components/common/Header'
import { Row, Col, Button, Card, InputGroup, Form } from 'react-bootstrap'

const OutbreaksScreen = () => {
  return (
    <>
      <Header />
      <Row
        className="mt-5"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <br />
        <div className="alert alert-info">
          <h1>Coming soon...</h1>
        </div>
      </Row>
    </>
  )
}

export default OutbreaksScreen
