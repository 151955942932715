import React, { useState } from 'react'
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import bg from '../../assets/imgs/background.jpg'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser, login, logout } from '../../redux_setup/slices/userSlice'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { useEffect } from 'react'

const Success = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [codeRequired, setCodeRequired] = useState(false)
  const [code, setCode] = useState('')
  const [status, setStatus] = useState('We are verifyng your payment. Hold on')
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  toast.configure()
  const navigate = useNavigate()
  const abortcontroller = new AbortController()
  const signal = abortcontroller.signal
  const user = useSelector(selectUser)

  useEffect(() => {
    const session_id = searchParams.get('session_id')
    const plan = searchParams.get('plan')

    const dataToSend = {
      plan,
      session_id,
    }

    fetch(process.env.REACT_APP_STRIPE_URL + `/api/stripe/record_app_session`, {
      method: 'POST',
      signal,
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson)
        //Hide Loader
        // dismissLoader()

        if (responseJson.error) {
          return toast.warning(responseJson.error, {
            position: toast.POSITION.TOP_CENTER,
          })
        }
        if (responseJson.affectedRows === 1) {
          toast.success(responseJson.message, {
            position: toast.POSITION.TOP_CENTER,
          })
          const toStore = {
            name: user.userName,
            id: user.user_id,
            token: process.env.REACT_APP_MAIN_TOKEN,
            user_type: 2,
          }

          localStorage.setItem(
            'temp_disease_adv_token',
            new URLSearchParams(toStore).toString(),
          )

          dispatch(
            login({
              userName: user.userName,

              user_id: user.user_id,
              token: process.env.REACT_APP_MAIN_TOKEN,
              user_type: 2,
            }),
          )

          return navigate('/')
        } else {
          return toast.success(responseJson.message, {
            position: toast.POSITION.TOP_CENTER,
          })
        }
      })
      .catch((error) => {
        return toast.warning('An error occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
        })
      })

    return function cleanup() {
      abortcontroller.abort()
    }
  }, [])

  return (
    <Row className="mt-5">
      <Col md={8} className="mt-2">
        <Card>
          <Card.Body>
            <div className="ml-1 alert alert-info">
              {status} {status && <i className="fa fa-spin fa-spinner" />}
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default Success
