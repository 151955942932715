import React, { useEffect, useRef, useState } from 'react'

import { Card, Container, Row, Col, ListGroup } from 'react-bootstrap'

import StaleMapContainer from '../components/StaleMapContainer'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import CheckoutForm from '../components/CheckoutForm'
import logo from '../assets/imgs/icon.png'
import './css/stripe.css'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser, login, logout } from '../redux_setup/slices/userSlice'
import Swal from 'sweetalert2'

const StripePre = (props) => {
  const [currentLocation, setCurrentLocation] = useState({
    latitude: 39.759,
    longitude: -88.157,
  })
  const publickey =
    'pk_test_51KT3JyK0ENw8dXdkfU7mWCyvsg3heifKTYaU4lx0Kxjez7LXj63Jh4fWej9TZtLqhRsy4ZMrIgZzMKBZ0i2RUGhS00pcTTQdXA'
  const stripePromise = loadStripe(publickey)

  console.log('currentLocation', currentLocation)
  const dispatch = useDispatch()
  const logoutHandler = () => {
    Swal.fire({
      title: 'Are you sure you wish to logout?',
      showCancelButton: true,
      icon: 'info',
      width: '600',
      confirmButtonText: 'Yes Logout',
      confirmButtonColor: 'blue',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(logout())
        localStorage.setItem('temp_disease_adv_token', 'null')
      } else {
        console.log('cancelled')
        // navigate('/')
      }
    })
  }
  return (
    <StaleMapContainer>
      <Container className="mt-5">
        <Card>
          <Card.Header className="text-center">
            <Card.Title as="h5">
              Welcome to Disease Adviser Premium Subscription
            </Card.Title>
          </Card.Header>
          <Card.Body className="bg-logo">
            <Row>
              <Col md={6} className="text-center">
                <h6>Enjoy exclusive features with our Premium Plan:</h6>
                <ListGroup className="my-3">
                  <ListGroup.Item>
                    Pin drop on the map to get disease risk
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Know how to protect yourself in advance
                  </ListGroup.Item>
                </ListGroup>
                <button className="btn btn-danger my-4" onClick={logoutHandler}>
                  Logout
                </button>
              </Col>
              <Col md={6}>
                <img src={logo} alt="logo" height={120} width={120} />
                <p>
                  <b>Enter your credentials below:</b>
                </p>
                <Elements stripe={stripePromise}>
                  <CheckoutForm />
                </Elements>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </StaleMapContainer>
  )
}

export default StripePre
