import React, { useState, useMemo } from 'react'
import Select from 'react-select'
import countryList from 'react-select-country-list'

function CountrySelect({ handler, currentVal }) {
  const [value, setValue] = useState('')
  const options = useMemo(() => countryList().getData(), [])

  return <Select options={options} value={currentVal} onChange={handler} />
}

export default CountrySelect
