import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import './css/checkoutForm.css' // Add custom CSS for styling
import { useSelector, useDispatch } from 'react-redux'
import { selectUser, login, logout } from '../redux_setup/slices/userSlice'
import { ToastContainer, toast } from 'react-toastify'
const CheckoutForm = () => {
  toast.configure()
  const user = useSelector(selectUser)
  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()
  const [loading, setLoading] = useState(false)
  const subscriptionSuccessful = (subscription) => {
    if (subscription?.id) {
      toast.success('Subscription was successful.', {
        position: toast.POSITION.TOP_CENTER,
      })

      toast.info(
        <span>
          Click{' '}
          <a
            href={subscription.latest_invoice.hosted_invoice_url}
            target="_blank"
          >
            here
          </a>{' '}
          to download receipt
        </span>,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        },
      )
      console.log(subscription.latest_invoice.hosted_invoice_url)

      // disptach to redux usertype 2
      dispatch(
        login({
          ...user,
          user_type: 2,
        }),
      )
      const toStore = {
        ...user,
        user_type: 2,
      }

      localStorage.setItem(
        'temp_disease_adv_token',
        new URLSearchParams(toStore).toString(),
      )
    }
  }
  const subscriptionFailed = (reason) => {
    toast.warning(reason, {
      position: toast.POSITION.TOP_CENTER,
    })

    // toast('Wow so easy !')
    // return <ToastContainer />
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    try {
      const res = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      })
      const { paymentMethod, error } = res

      if (error) {
        console.log(error)
        subscriptionFailed(error.message)
        setLoading(false)
        return
      }

      const response = await fetch(
        `${process.env.REACT_APP_STRIPE_PAYMENTS_URL}api/stripe/create-subscription`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            paymentMethodId: paymentMethod.id,
            priceId: 'price_1P2tDxK0ENw8dXdkSgWpGsny',
            email: user?.email,
          }),
        },
      )

      const subscription = await response.json()
      console.log(subscription)
      if (subscription?.error) {
        subscriptionFailed(
          subscription?.error?.message + '. Request for admin help',
        )
      } else {
        subscriptionSuccessful(subscription)
      }
    } catch (e) {
      console.log(e)
      subscriptionFailed(e.message)
    }

    setLoading(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button
        className="btn btn-primary my-5"
        type="submit"
        disabled={!stripe || loading}
      >
        Subscribe &nbsp;
        {loading && <i className="fa fa-spin fa-spinner" />}
      </button>
    </form>
  )
}

export default CheckoutForm
