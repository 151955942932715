import React, { useEffect, useRef, useState } from 'react'
import {
  MapContainer,
  LayersControl,
  TileLayer,
  ZoomControl,
  Marker,
  Popup,
  useMapEvents,
} from 'react-leaflet'
import L from 'leaflet'

import EsriLeafletGeoSearch from 'react-esri-leaflet/plugins/EsriLeafletGeoSearch'
import { Accordion, Button, Card, Modal, Nav, NavItem } from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom'
import './css/homestyles.css'
import DiseaseIcon from '../components/utilities/DiseaseIcon'
import CustomMarker from '../components/utilities/CustomMarker'
import { fetchDiseaseArray } from '../functions'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser, login, logout } from '../redux_setup/slices/userSlice'
import Swal from 'sweetalert2'
import { iso1A2Code } from '@ideditor/country-coder'
import { empty, selectDisease } from '../redux_setup/slices/diseaseSlice'
import useWindowDimensions from '../components/hooks/useDimensions'
import CircularMenu from '../components/common/CircularMenu'
import BottomNavigation from '../components/common/BottomNavigation'
import StaleMapContainer from '../components/StaleMapContainer'
// import codegrid from 'react-codegrid'
// const externalUrl = "https://diseaseadviser.com";

const Landing = (props) => {
  const [currentLocation, setCurrentLocation] = useState({
    latitude: 39.759,
    longitude: -88.157,
  })

  console.log('currentLocation', currentLocation)
  return (
    <StaleMapContainer>
      <Card>
        <Card.Header className="text-center">
          {/* <Card.Title as="h5">Welcome to Disease Advisor</Card.Title> */}
          <img src={require('../assets/imgs/icon.png')} height={200} />
        </Card.Header>
        <Card.Body>
          <h3 className="my-5 text-center">Welcome to Disease Advisor</h3>
          <div className="d-flex flex-row justify-content-around">
            <Link to="/login" className="landing-wrap">
              <button className="landing-button">Login</button>
            </Link>

            <Link to="/register" className="landing-wrap ">
              <button className="landing-button secondary-landing-button">
                Register
              </button>
            </Link>
          </div>
        </Card.Body>
      </Card>
    </StaleMapContainer>
  )
}

export default Landing
