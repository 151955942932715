import React from 'react'
import { Nav, NavItem } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

function BottomNavigation() {
  const tabs = [
    {
      route: '/home',
      icon: 'home',
      label: 'Home',
    },
    {
      route: '/plan-trip',
      icon: 'plane',
      label: 'Trips',
    },
    {
      route: '/diseases',
      icon: 'snowflake',
      label: 'Diseases',
    },
    {
      route: '/account',
      icon: 'user',
      label: 'Account',
    },
  ]
  return (
    <nav className="navbar fixed-bottom bg-info" role="navigation">
      <Nav className="w-100">
        <div className=" d-flex flex-row justify-content-around w-100">
          {tabs.map((tab, index) => (
            <NavItem key={`tab-${index}`}>
              <NavLink
                to={tab.route}
                className="nav-link"
                activeClassName="active"
              >
                <div className="row d-flex flex-column justify-content-center align-items-center text-white">
                  {/* <FontAwesomeIcon size="lg" icon={tab.icon}/> */}
                  <i className={`fa fa-${tab.icon} text-center`} />
                  <div>{tab.label}</div>
                </div>
              </NavLink>
            </NavItem>
          ))}
        </div>
      </Nav>
    </nav>
  )
}

export default BottomNavigation
