import L from 'leaflet'
import marker from '../../assets/imgs/disease_marker.png'

const DiseaseIcon = L.icon({
  iconUrl: marker,
  iconSize: [40, 40],
  iconAnchor: [12, 12],
  popupAnchor: [0, 0],
})

export default DiseaseIcon
