import React, { useState } from 'react'
import { Button, Card, Container, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import bg from '../../assets/imgs/background.jpg'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser, login, logout } from '../../redux_setup/slices/userSlice'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import CountrySelect from '../../components/CountrySelect'
import StaleMapContainer from '../../components/StaleMapContainer'
const Register = () => {
  const [email, setEmail] = useState('')

  const [userName, setUserName] = useState('')
  const [userCountry, setUserCountry] = useState()
  const [userDob, setUserDob] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const dispatch = useDispatch()
  toast.configure()
  const registerHandler = () => {
    console.log('registerHandler')
    var dataToSend = {
      user: {
        email: email,
        password: password,
        dob: userDob,
        country: userCountry,
        username: userName,
      },
    }
    fetch(process.env.REACT_APP_MAIN_URL + '?registerr=1', {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + process.env.REACT_APP_MAIN_TOKEN,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        //Hide Loader
        // console.log(responseJson);
        if (responseJson[0].response === 'proceed') {
          //registration is successful --- get new id

          let newUserId = responseJson[0].newUserId
          const toStore = {
            name: userName,
            id: newUserId,
            token: process.env.REACT_APP_MAIN_TOKEN,
            user_type: 1,
            email: email,
          }

          localStorage.setItem(
            'temp_disease_adv_token',
            new URLSearchParams(toStore).toString(),
          )
          dispatch(
            login({
              userName: userName,

              user_id: newUserId,
              token: process.env.REACT_APP_MAIN_TOKEN,
              user_type: 1,
              email: email,
            }),
          )
          return toast.success('Registration was successful', {
            position: toast.POSITION.TOP_CENTER,
          })
        } else {
          return toast.warning('Registration was not successful', {
            position: toast.POSITION.TOP_CENTER,
          })
        }
      })
      .catch((error) => {
        return toast.error('Error, ' + error.message, {
          position: toast.POSITION.TOP_CENTER,
        })
      })
  }

  const countryChangeHandler = (value) => {
    if (value) {
      console.log(value)
      setUserCountry(value)
    }
  }
  return (
    <StaleMapContainer>
      <Container style={{ width: '50%' }} className="content-centered p-2">
        <Card>
          <Card.Header>
            <Card.Title as="h5">Register at Disease Advisor</Card.Title>
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formGroupEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter country"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupEmail">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter username"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupEmail">
                <Form.Label>Country</Form.Label>
                {/* <Form.Control
                  type="text"
                  placeholder="Enter country"
                  value={userCountry}
                  onChange={(e) => setUserCountry(e.target.value)}
                /> */}
                <CountrySelect
                  handler={countryChangeHandler}
                  currentVal={userCountry}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupEmail">
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  type="date"
                  value={userDob}
                  onChange={(e) => setUserDob(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupPassword">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupPassword">
                <div className="d-flex flex-row justify-content-between">
                  <Button
                    onClick={registerHandler}
                    disabled={
                      !email ||
                      !userName ||
                      !password ||
                      !userCountry ||
                      password !== confirmPassword
                    }
                  >
                    Sign Up
                  </Button>

                  <Link to="/login">Login Instead</Link>
                  <Link to="/">Back to home</Link>
                </div>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </StaleMapContainer>
  )
}

export default Register
