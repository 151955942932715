import React, { useState } from 'react'
import Header from '../components/common/Header'
import { Row, Col, Button, Card, InputGroup, Form } from 'react-bootstrap'
const businessesDummy = [
  {
    id: 1,
    type: 'Travel Doctors',
    desc: 'Find travel doctors recommended by Disease Advisor.',
    img: 'https://picsum.photos/536/354',
    slug: 'travel+doctors',
  },
  {
    id: 2,
    type: 'Pharmacies',
    desc: 'Find pharmacies recommended by Disease Advisor.',
    img: 'https://picsum.photos/536/354',
    slug: 'pharmacies',
  },
  {
    id: 3,
    type: 'Hospitals',
    desc: 'Find hospitals recommended by Disease Advisor.',
    img: 'https://picsum.photos/536/354',
    slug: 'hospitals',
  },
  {
    id: 4,
    type: 'Hotels',
    desc: 'Find hotels recommended by Disease Advisor.',
    img: 'https://picsum.photos/536/354',
    slug: 'hotels',
  },
  {
    id: 5,
    type: 'Restaurants',
    desc: 'Find restaurants recommended by Disease Advisor.',
    img: 'https://picsum.photos/536/354',
    slug: 'restaurants',
  },
  {
    id: 6,
    type: 'Public Transport',
    desc: 'Find public transport recommended by Disease Advisor.',
    img: 'https://picsum.photos/536/354',
    slug: 'cabs',
  },
]

const amenityUrl = `https://www.google.com/maps/search/`

const BusinessesScreen = () => {
  const [businesses, setBusinesses] = useState(businessesDummy)
  return (
    <>
      <Header />
      <Row className="p-2" style={{ marginTop: 60 }}>
        <Col md={12}>
          <InputGroup size="lg">
            <Form.Control
              aria-label="Large"
              aria-describedby="inputGroup-sizing-sm"
              placeholder="Search here.."
            />
            <InputGroup.Text id="inputGroup-sizing-lg">
              <i className="fa fa-search" />
            </InputGroup.Text>
          </InputGroup>
        </Col>
        <Row className="mt-2">
          {businesses.map((business) => (
            <Col md={4} className="business-card mt-2" key={business.id}>
              <a href={amenityUrl + business.slug} target="_blank">
                <Card style={{ width: '100%' }}>
                  <Card.Img variant="top" src={business.img} />
                  <Card.Body>
                    <Card.Title>{business.type}</Card.Title>
                    <Card.Text>{business.desc}</Card.Text>
                    <Button variant="primary">View</Button>
                  </Card.Body>
                </Card>
              </a>
            </Col>
          ))}
        </Row>
      </Row>
    </>
  )
}

export default BusinessesScreen
