import React, { useState } from 'react'
import { Button, Card, Container, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import bg from '../../assets/imgs/background.jpg'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser, login, logout } from '../../redux_setup/slices/userSlice'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
// import 'react-toastify/dist/ReactToastify.css'
import StaleMapContainer from '../../components/StaleMapContainer'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  toast.configure()

  const loginHandler = () => {
    console.log('working', email, password)
    /*prepare form body string */
    var dataToSend = {
      user: {
        email: email,
        password: password,
      },
    }
    /*finish forming formbody string*/

    /*fetch request */
    fetch(process.env.REACT_APP_MAIN_URL + 'index.php?login=1', {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + process.env.REACT_APP_MAIN_TOKEN,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        //Hide Loader
        // dismissLoader()
        if (responseJson[0].response === 'proceed') {
          let returnedUserId = responseJson[0].returnedUserId
          const returnedUserName = responseJson[0].username
          const returnedUserType = responseJson[0].user_type

          //usertype.. if usertype ==2 proceed to dashboard
          // if usertype ==.. means its unpriviledged user.. move to checking stripe
          //encode and store in localstorage
          // if (returnedUserType == 2) {
          const toStore = {
            name: returnedUserName,
            id: returnedUserId,
            token: process.env.REACT_APP_MAIN_TOKEN,
            user_type: returnedUserType || 1,
            email: email,
          }

          localStorage.setItem(
            'temp_disease_adv_token',
            new URLSearchParams(toStore).toString(),
          )
          dispatch(
            login({
              userName: returnedUserName,

              user_id: returnedUserId,
              token: process.env.REACT_APP_MAIN_TOKEN,
              user_type: returnedUserType || 1,
              email: email,
            }),
          )
          return toast.success('Login was successful', {
            position: toast.POSITION.TOP_CENTER,
          })
          // } else {
          //   navigate('/subscribe')
          //   return toast.success(
          //     'Login was successful but you gotta cheeck stripe first',
          //     {
          //       position: toast.POSITION.TOP_CENTER,
          //     },
          //   )
          // }
        } else {
          //   Alert.alert(
          //     'Wrong Username or password',
          //     'Please check and try again',
          //   )

          return toast.warning('Wrong username or password', {
            position: toast.POSITION.TOP_CENTER,
          })
        }
      })
      .catch((error) => {
        //Hide Loader
        // dismissLoader()
        // alert(error.message)
        return toast.warning('An error occured while reaching the server', {
          position: toast.POSITION.TOP_CENTER,
        })
      })
  }
  return (
    <StaleMapContainer>
      <Card>
        <Card.Header>
          <Card.Title as="h5">Login to Disease Advisor</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <div className="mb-3" controlId="formGroupPassword">
              <div className="d-flex flex-row justify-content-between">
                <Button onClick={loginHandler} disabled={!email || !password}>
                  Login
                </Button>

                <Link to="/register">
                  <span className="mx-2">Register</span>
                </Link>
                <Link to="/">
                  <span className="mx-2">Home</span>
                </Link>

                {/* <div className="d-flex flex-column">
                  <Link to="/register">Register</Link>
                  <Link to="/">Back to home</Link>
                </div> */}
              </div>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </StaleMapContainer>
  )
}

export default Login
