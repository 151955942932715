import React from 'react'
import { NavLink } from 'react-router-dom'

import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { Button } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux'
import { logout } from '../../redux_setup/slices/userSlice'

const Header = () => {
  const dispatch = useDispatch()
  const logoutHandler = () => {
    Swal.fire({
      title: 'Are you sure you wish to logout?',
      showCancelButton: true,
      icon: 'info',
      width: '600',
      confirmButtonText: 'Yes Logout',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('Delete', '', 'success')
        dispatch(logout())
        localStorage.setItem('temp_disease_adv_token', 'null')
      } else {
        console.log('cancelled')
        // navigate('/')
      }
    })
  }
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      // bg="primary"
      // variant="dark"
      fixed="top"
      style={{ backgroundColor: '#f0f5f6' }}
    >
      <Container>
        <Navbar.Brand as={NavLink} to="/">
          Disease Advisor
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={NavLink} to="/plan-trip" className="logged-in-link">
              Plan Trip
            </Nav.Link>
            <Nav.Link as={NavLink} to="/diseases" className="logged-in-link">
              Disease Database
            </Nav.Link>
            {/* <Nav.Link as={NavLink} to="/businesses" className="logged-in-link">
              Businesses
            </Nav.Link>
            <Nav.Link
              href="https://diseaseadviser.com/partners/"
              className="logged-in-link"
              target="_blank"
            >
              Partners
            </Nav.Link> */}
          </Nav>
          <Nav>
            <Nav.Link
              as={Button}
              onClick={logoutHandler}
              className="logged-in-link btn-danger text-white bg-red"
            >
              <i className="fa fa-power-off" /> Logout
            </Nav.Link>
          </Nav>
          {/* <Nav>
            <NavDropdown
              title="Account"
              id="collasible-nav-dropdown"
              // className="logged-in-link"
            >
              <NavDropdown.Divider />
              <NavDropdown.Item as={Button} onClick={logoutHandler}>
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Header
