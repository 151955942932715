import React, { useEffect, useRef, useState } from 'react'
import {
  MapContainer,
  LayersControl,
  TileLayer,
  ZoomControl,
  Marker,
  Popup,
  useMapEvents,
} from 'react-leaflet'
import L from 'leaflet'

import EsriLeafletGeoSearch from 'react-esri-leaflet/plugins/EsriLeafletGeoSearch'
import { Accordion, Button, Card, Modal, Nav, NavItem } from 'react-bootstrap'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import './css/homestyles.css'
import DiseaseIcon from '../components/utilities/DiseaseIcon'
import CustomMarker from '../components/utilities/CustomMarker'
import { fetchDiseaseArray } from '../functions'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser, login, logout } from '../redux_setup/slices/userSlice'
import Swal from 'sweetalert2'
import { iso1A2Code } from '@ideditor/country-coder'
import { empty, selectDisease } from '../redux_setup/slices/diseaseSlice'
import useWindowDimensions from '../components/hooks/useDimensions'
import CircularMenu from '../components/common/CircularMenu'
import BottomNavigation from '../components/common/BottomNavigation'
// import codegrid from 'react-codegrid'
// const externalUrl = "https://diseaseadviser.com";

const HomeScreen = (props) => {
  const [showMenu, setShowMenu] = useState(false)
  const [currentCoordinates, setCurrentCoordinates] = useState([])
  const [thisMap, setThisMap] = useState()
  const [fetchDiseases, setFetchDiseases] = useState([])
  const [searchFinished, setSearchFinished] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [sections, setSections] = useState([])
  const [sectionsLoading, setSectionsLoading] = useState(false)
  const [dis, setDis] = useState('')
  const [currentLocation, setCurrentLocation] = useState({
    latitude: 39.759,
    longitude: -88.157,
  })
  const [permissionStatus, setPermissionStatus] = useState('pending')
  const user = useSelector(selectUser)
  const disease = useSelector(selectDisease)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const logoutHandler = () => {
    Swal.fire({
      title: 'Are you sure you wish to logout?',
      showCancelButton: true,
      icon: 'info',
      width: '600',
      confirmButtonText: 'Yes Logout',
      confirmButtonColor: 'blue',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(logout())
        localStorage.setItem('temp_disease_adv_token', 'null')
      } else {
        console.log('cancelled')
        // navigate('/')
      }
    })
  }
  const { height, width } = useWindowDimensions()
  // let accessUrl = 'https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
  let accessUrl = 'http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}'

  useEffect(() => {
    // const getLocation = async () => {
    //   try {
    //     const { coords } = await navigator.geolocation.getCurrentPosition()
    //     const { latitude, longitude } = coords
    //     setCurrentLocation({ latitude, longitude })
    //     setPermissionStatus('granted')
    //   } catch (error) {
    //     console.error('Error getting current location:', error.message)
    //     setPermissionStatus('denied')
    //   }
    // }

    // // Check if the Geolocation API is available in the browser
    // if ('geolocation' in navigator) {
    //   getLocation()
    // } else {
    //   console.error('Geolocation is not supported in this browser.')
    //   setPermissionStatus('denied')
    // }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error)
    } else {
      console.log('Geolocation not supported')
    }

    function success(position) {
      const latitude = position.coords.latitude
      const longitude = position.coords.longitude
      console.log(`Latitude: ${latitude}, Longitude: ${longitude}`)
      setCurrentLocation({ latitude, longitude })
    }

    function error() {
      console.log('Unable to retrieve your location')
    }
  }, []) // The empty dependency array ensures that this effect runs only once on component mount

  const requestPermission = () => {
    navigator.geolocation.getCurrentPosition(
      () => {
        setPermissionStatus('granted')
      },
      (error) => {
        console.error('Error getting current location:', error.message)
        setPermissionStatus('denied')
      },
    )
  }

  useEffect(() => {
    if (disease) {
      // console.log('dddddd', disease?.diseasename)
      setDis(disease?.diseasename)
      viewDisease(disease)
    }
    return () => {
      dispatch(empty())
    }
  }, [disease])

  const viewDisease = (disease) => {
    setModalOpen(true)
    setSectionsLoading(true)
    setSections([])

    var dataToSend = {
      disease: {
        id: disease.disease_id,
      },
    }

    fetch(process.env.REACT_APP_MAIN_URL + '?diseasesInfo=d', {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + process.env.REACT_APP_MAIN_TOKEN,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(dataToSend),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        //no longer loading
        setSectionsLoading(false)
        if (responseJson.length) {
          setSections(responseJson)
          // SetNoData(false);
        }
        //no longer fetching
        // SetIsFetching(false);
      })
      .catch((error) => {
        setSectionsLoading(false)

        // SetNoData(true);
        // if (noData && !isFetching) {
        // alert(error.message);
        // }

        // SetIsFetching(false);
      }) //to catch the errors if any
  }

  const processSearchResults = (r) => {
    setSearchFinished(false)
    setCurrentCoordinates([r.latlng.lat, r.latlng.lng])
    // const country
    const country = r.results[0].properties.Country
    setFetchDiseases([])
    fetchDiseaseArray(
      r.latlng.lng,
      r.latlng.lat,
      country,
      setFetchDiseases,
      setSearchFinished,
    )

    // console.log(fetchDiseases)
  }

  const position = [35.72428729739558, 51.447000503540046]
  function MapEvents() {
    const map = useMapEvents({
      click: (e) => {
        // setFetchDiseases([])
        setSearchFinished(false)
        const { lat, lng } = e.latlng
        // console.log(iso1A2Code([lng, lat]))
        setCurrentCoordinates([lat, lng])
        const country = iso1A2Code([lng, lat])
        setFetchDiseases([])
        fetchDiseaseArray(
          lng,
          lat,
          country,
          setFetchDiseases,
          setSearchFinished,
        )
      },
    })
    return null
  }
  console.log('currentLocation', currentLocation)
  return (
    <>
      <MapContainer
        id="mapId"
        zoom={10}
        minZoom={4}
        center={[currentLocation?.latitude, currentLocation?.longitude]}
        style={{ height: height }}
        zoomControl={false}
        whenCreated={setThisMap}
        key={currentLocation?.latitude}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          url={accessUrl}
          maxZoom={18}
          noWrap={true}
          // tileSize={1024}
          // id="mapbox/streets-v11"
          // zoomOffset={-2}
          // accessToken={mapboxToken}
          subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
        />
        <ZoomControl position="bottomright" />

        <EsriLeafletGeoSearch
          useMapBounds={false}
          position="topleft"
          expanded
          id="srch"
          eventHandlers={{
            // requeststart: () => console.log('Started request...'),
            // requestend: () => console.log('Ended request...'),
            results: (r) => processSearchResults(r),
          }}
        />

        {currentCoordinates.length > 0 && (
          //   <Marker position={currentCoordinates} icon={DiseaseIcon}>
          //     <Popup zoomAnimation>
          //       A pretty CSS3 popup. <br /> Easily customizable.
          //     </Popup>
          //   </Marker>
          // )}
          <CustomMarker
            map={thisMap}
            data={{
              position: currentCoordinates || [20.27, -156],
              diseases: fetchDiseases,
              searchState: searchFinished,
            }}
            isActive
          />
        )}
        <MapEvents />
        <div className="buttons-container row">
          <div className="col-md-3 col-sm-6 col-xs-6">
            <Link to="plan-trip">
              <button className="m-1 menu-btn">
                <i className="fa fa-car mr-1" />{' '}
                <span className="m-1">Plan Trip</span>
              </button>
            </Link>
          </div>
          {/* 
          <div className="col-md-3 col-sm-6 col-xs-6">
            <Link to="outbreaks">
              <button className="m-1 menu-btn">
                <i className="fa fa-exclamation-triangle mr-1" />{' '}
                <span className="m-1">Outbreaks</span>
              </button>
            </Link>
          </div> */}

          {/* <div className="col-md-3 col-sm-6 col-xs-6">
            <Link to="businesses">
              <button className="m-1 menu-btn">
                <i className="fa fa-book mr-1" />{' '}
                <span className="m-1">Businesses</span>
              </button>
            </Link>
          </div> */}
          <div className="col-md-3 col-sm-6 col-xs-6">
            <Link to="diseases">
              <button className="m-1 menu-btn">
                <i className="fa fa-virus mr-1" />{' '}
                <span className="m-1">Database</span>
              </button>
            </Link>
          </div>
          {/* <div className="col-md-3 col-sm-6 col-xs-6">
            <a href="https://diseaseadviser.com/partners/" target="_blank">
              <button className="m-1 menu-btn">
                <i className="fa fa-hands mr-1" />{' '}
                <span className="m-1">Partners</span>
              </button>
            </a>
          </div> */}
          {!user && (
            <div className="col-md-3 col-sm-6 col-xs-6">
              <Link to="login">
                <button className="m-1 menu-btn">
                  <i className="fa fa-user mr-1" />
                  <span className="m-1">Login</span>
                </button>
              </Link>
            </div>
          )}
        </div>
      </MapContainer>
      <div className="settings-pane row">
        {/* <Button
          variant="light"
          style={{ borderRadius: '50%' }}
          onClick={() => setShowMenu(!showMenu)}
        >
          <i className="fa fa-bars" />
        </Button> */}
        <CircularMenu logoutHandler={logoutHandler} navigate={navigate} />
      </div>

      {showMenu && (
        <div className="menu-pane">
          <Card>
            <Card.Header>
              <Card.Title as="h5">Disease Advisor</Card.Title>
              {/* <ul>
                  {fetchDiseases.map((disease, i) => (
                    <li key={i}>{disease.diseasename}</li>
                  ))}
                </ul> */}
            </Card.Header>
            <Card.Body>
              {user && 1 == 2 && (
                <div className="d-flex justify-content-between m-2 mb-5">
                  <a className="menu-ribbon" href="https://google.com">
                    <i className="fa fa-user-circle fa-2x" />
                    <span>Account</span>
                  </a>
                  <div className="menu-ribbon">
                    <i className="fa fa-credit-card fa-2x" />
                    <span>Payments</span>
                  </div>
                  <div className="menu-ribbon">
                    <i className="fa fa-exclamation-circle fa-2x" />
                    <span>Risk History</span>
                  </div>
                </div>
              )}

              <div className=" d-flex justify-content-between m-2 mb-5 to-be-hidden">
                <Link to="plan-trip" className="menu-ribbon">
                  <i className="fa fa-car fa-2x" />
                  <span>Plan Trip</span>
                </Link>
                <Link to="businesses" className="menu-ribbon">
                  <i className="fa fa-book fa-2x" />
                  <span>Businesses</span>
                </Link>
                <Link to="diseases" className="menu-ribbon">
                  <i className="fa fa-virus fa-2x" />
                  <span>Diseases</span>
                </Link>
              </div>
              <div className="d-flex justify-content-between m-2 mb-5">
                <a
                  className="menu-ribbon"
                  href="https://diseaseadviser.com/info/about.html"
                  target="_blank"
                >
                  <i className="fa fa-question-circle fa-2x" />
                  <span>About</span>
                </a>
                <a
                  className="menu-ribbon"
                  href="https://diseaseadviser.com/info/privacy_policy.html"
                  target="_blank"
                >
                  <i className="fa fa-user-secret fa-2x" />
                  <span>Privacy Policy</span>
                </a>
                {/* <a
                  className="menu-ribbon"
                  href="https://diseaseadviser.com/info/terms_conditions.html"
                  target="_blank"
                >
                  <i className="fa fa-flag fa-2x" />
                  <span>Terms & Conditions</span>
                </a> */}
                <a
                  className="menu-ribbon"
                  href="https://diseaseadviser.com/info/eula.html"
                  target="_blank"
                >
                  <i className="fa fa-etsy fa-2x" />
                  <span>EULA</span>
                </a>
              </div>
              <div className="d-flex justify-content-between m-2 mb-5">
                {/* <a
                  className="menu-ribbon"
                  href="https://diseaseadviser.com/info/acknowledgement.html"
                  target="_blank"
                >
                  <i className="fa fa-life-ring fa-2x" />
                  <span>Acknowledgements</span>
                </a> */}

                {user ? (
                  <a className="menu-ribbon" onClick={logoutHandler}>
                    <i className="fa fa-power-off fa-2x" />
                    <span>Logout</span>
                  </a>
                ) : (
                  <Link to="login" className="menu-ribbon">
                    <i className="fa fa-user fa-2x" />
                    <span>Login</span>
                  </Link>
                )}
              </div>
            </Card.Body>
          </Card>
        </div>
      )}

      <BottomNavigation />

      <Modal show={modalOpen} onHide={() => setModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Info about {dis}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {sectionsLoading ? (
            <div className="alert alert-info">Loading...</div>
          ) : (
            <>
              {sections.length > 0 ? (
                <Accordion defaultActiveKey="0">
                  {sections.map((section, index) => (
                    <Accordion.Item eventKey={index} key={index}>
                      <Accordion.Header>
                        <span
                          style={{
                            backgroundColor: '#e7f1ff',
                            fontWeight: 'bold',
                          }}
                        >
                          {section.title}
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>{section.content}</Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              ) : (
                <div className="alert alert-info text-center">
                  Nothing to display
                </div>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalOpen(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default HomeScreen
